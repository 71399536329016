import React, { useEffect } from 'react';
import { Main, Nav } from '@/components/layout';
import {
  Box,
  Button,
  Image as ChakraImage,
  Flex,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Image from '@/assets/images/jetlag-full.png';
import googlePlayButton from '@/assets/images/googleplay-button.png';
import appStoreButton from '@/assets/images/appstore-button.png';

export const DownloadAppPage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    document.title = 'Success | Timeshifter®';
  }, []);

  return (
    <>
      <Nav variant="default" />
      <Main>
        <Box textAlign="center">
          <Text fontSize="2xl" fontWeight="500" mb="2">
            Success!
          </Text>
          <Text fontWeight="500" mb="6">
            If you don’t already have the app,{isMobile && <br />} download it
            now
          </Text>
          <Flex mb="6" justifyContent="center" gap="5">
            <Button
              as="a"
              href="https://apps.apple.com/us/app/timeshifter/id1380684374"
              width="131px"
              height="38px"
              borderRadius="2px"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download from App Store"
              style={{
                backgroundImage: `url(${appStoreButton})`,
                backgroundSize: 'cover',
              }}
              _hover={{
                opacity: '0.8',
              }}
            />
            <Button
              as="a"
              href="https://play.google.com/store/apps/details?id=com.timeshifter.timeshifter"
              width="131px"
              height="38px"
              borderRadius="2px"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download from Google Play"
              style={{
                backgroundImage: `url(${googlePlayButton})`,
                backgroundSize: 'cover',
              }}
              _hover={{
                opacity: '0.8',
              }}
            />
          </Flex>
          <ChakraImage
            src={Image}
            alt="Screenshots from the Jet lag app"
            w="100%"
            maxW="410px"
            mx="auto"
          />
        </Box>
      </Main>
    </>
  );
};
