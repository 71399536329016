import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Image as ChakraImage,
} from '@chakra-ui/react';
import { couponClaimSchema } from './validations';
import { useVoucherTest, useGeneralRedeem } from '@/hooks/useVoucher';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { AlreadyClaimedOffer } from '@/components/offers';
import { useAppDispatch } from '@/store';
import { setOffer } from '@/store/offer/offer.slice';
import Image from '@/assets/images/claim-coupon.svg';

export const CouponClaimForm: React.FC = () => {
  const location = useLocation();
  const initialValues = { code: '' };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutate: voucherTestMutate } = useVoucherTest();
  const { mutate } = useGeneralRedeem();
  const [error, setError] = useState<string>('');
  const [alreadyClaimed, setAlreadyClaimed] = useState<boolean>(false);
  const { code } = useParams();

  const onErr = (err: any) => {
    if (err?.response?.data?.message === 'Voucher Usage Exceeded') {
      setAlreadyClaimed(true);
    } else {
      setError(
        'Code not found! Please check that you have entered the code correctly.'
      );
    }
  };

  return (
    <>
      {!alreadyClaimed && (
        <>
          <ChakraImage
            src={Image}
            alt="Offer Redeem image"
            width="250px"
            my="6"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={couponClaimSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setError('');
              voucherTestMutate(
                { code: values.code },
                {
                  onSuccess: (response: {
                    data: {
                      description: string;
                      currency: 'eur' | 'usd' | null;
                    };
                  }) => {
                    const currency = response?.data?.currency;

                    if (currency) {
                      sessionStorage.setItem('currency', currency);
                    }

                    mutate(
                      { code: values.code },
                      {
                        onSuccess: ({ data }) => {
                          setSubmitting(false);
                          if (data.partnerVerification) {
                            if (data.partner === 'MileagePlus') {
                              return navigate(
                                RedirectPaths[RedirectPathsEnum.MILEAGE_PLUS]
                              );
                            } else if (data.partner === 'Miles&More') {
                              return navigate(
                                RedirectPaths[RedirectPathsEnum.MILES_MORE]
                              );
                            }
                          }
                          dispatch(
                            setOffer({
                              type: data.type || '',
                              description: data.description || '',
                              voucherRuleId: data.ruleId || '',
                              name: data.name || '',
                              provider: data.airline || '',
                              free: !data.paid || undefined,
                              code: values.code || '',
                              discount: data.discount || false,
                            })
                          );

                          if (data.partner === 'MileagePlus') {
                            return navigate(
                              RedirectPaths[
                                RedirectPathsEnum.MILEAGE_PLUS_OFFER
                              ]
                            );
                          } else if (data.partner === 'Miles&More') {
                            return navigate(
                              RedirectPaths[RedirectPathsEnum.MILES_MORE_OFFER]
                            );
                          }
                          navigate(RedirectPaths[RedirectPathsEnum.OFFER]);
                        },
                        onError: (error: any) => {
                          setError(
                            error?.response?.data?.message ||
                              error?.message ||
                              'Something went wrong'
                          );
                          setSubmitting(false);
                        },
                      }
                    );
                  },
                  onError: (err: any) => {
                    onErr(err);
                    setSubmitting(false);
                  },
                }
              );
            }}
          >
            {({
              setFieldValue,
              submitForm,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              isValid,
            }) => {
              useEffect(() => {
                if (code) {
                  setFieldValue('code', code).then(() => {
                    // Delay the submission to ensure all form states are updated
                    setTimeout(() => submitForm(), 100);
                  });
                }
              }, [location.search, setFieldValue, submitForm]);

              return (
                <Box as={Form} mt="3" minWidth={{ base: '100%', sm: '254px' }}>
                  {error && (
                    <Text
                      color="error.500"
                      fontSize="md"
                      mb="6"
                      mx="auto"
                      maxWidth={{ base: '100%', sm: '300px' }}
                      textAlign="center"
                    >
                      {error}
                    </Text>
                  )}
                  <FormControl
                    id="code"
                    isInvalid={!!error || (touched.code && !!errors.code)}
                  >
                    <Input
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter code"
                      _placeholder={{ textAlign: 'center' }}
                      textAlign="center"
                    />
                    <FormErrorMessage fontSize="md">
                      {errors.code}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    colorScheme="orange"
                    width="100%"
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isValid}
                    mt="4"
                    fontWeight="500"
                  >
                    Next
                  </Button>
                </Box>
              );
            }}
          </Formik>
        </>
      )}
      {alreadyClaimed && <AlreadyClaimedOffer />}
    </>
  );
};
