import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Image as ChakraImage,
} from '@chakra-ui/react';
import AppleSignin from 'react-apple-signin-auth';

import { auth } from '../firebaseConfig';
import Image from '@/assets/images/jetlag-half.png';
import { ReactComponent as AppleLogo } from '@/assets/images/apple-logo.svg';
import { ReactComponent as GoogleLogo } from '@/assets/images/google-logo.svg';
import { ContentWithSidebarLayout, Main, Nav } from '@/components/layout';
import { SignUpForm, SignUpFormValues } from '@/components/forms';
import { TextDivider } from '@/components/shared';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { useAppDispatch } from '@/store';
import { selectOffer } from '@/store/offer/offer.slice';
import { AppState } from '@/store';
import { setCurrentAccount } from '@/store/account/current-account.slice';
import { useSignUp } from '@/hooks/useAuth';
import { useUserConfirmation } from '@/hooks/useMileagePlus';
import { selectMileagePlus } from '@/store/mileagePlus/mileage-plus.slice';
import { useCheckUsage, useVoucherRedeem } from '@/hooks/useVoucher';
import { useAppleSignIn, useGoogleSignIn } from '@/hooks/useAuth';
import { AlreadyClaimedOffer } from '@/components/offers';
import { splitDescription } from '@/utils/helpers/string.helper';

export const SignUpPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { jwtDecode } = require('jwt-decode');

  const { mutate } = useSignUp();
  const { mutate: userConfirmationMutate } = useUserConfirmation();
  const { mutate: appleSignInMutate } = useAppleSignIn();
  const { mutate: googleSignInMutate } = useGoogleSignIn();
  const { mutate: checkUsageMutate } = useCheckUsage();
  const { mutate: voucherRedeemMutate } = useVoucherRedeem();

  const offer = useSelector((state: AppState) => selectOffer(state));
  const mileagePlus = useSelector((state: AppState) =>
    selectMileagePlus(state)
  );
  const [oauthError, setOAuthError] = useState('');
  const [error, setError] = useState('');
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);

  useEffect(() => {
    // window.onbeforeunload = () => true;

    if (!offer) {
      // window.location.href = 'https://www.timeshifter.com/';
    }
  }, [offer]);

  useEffect(() => {
    document.title = 'Create account | Timeshifter®';
  }, []);

  const descriptionSplit = offer?.discount
    ? splitDescription(offer?.description || '')
    : '';

  const handleAppleLoginSuccess = (response: any) => {
    appleSignInMutate(
      { appleIdToken: response?.authorization?.id_token },
      {
        onSuccess: ({ token }) => {
          // Apple does not show the e-mail directly after the first login,
          // so we need a hacky way to get it.
          const email =
            response?.user?.email ||
            jwtDecode(response?.authorization?.id_token)?.email ||
            '';
          saveEmailAndGoToNextStep(email.toLowerCase(), token);
        },
        onError: (error: any) => {
          console.error('Error signing in with Apple', error);
          setOAuthError('Error signing in with Apple');
        },
      }
    );
  };
  const handleAppleLoginFailure = (error: any) => {
    console.error('Failed to login with Apple', error);
    setOAuthError('Failed to login with Apple');
  };

  const handleGoogleLoginSuccess = (response: any) => {
    const { tokenId } = response;
    const credential = GoogleAuthProvider.credential(tokenId);
    signInWithCredential(auth, credential)
      .then((result) => {
        googleSignInMutate(
          { googleIdToken: tokenId },
          {
            onSuccess: ({ token }) => {
              saveEmailAndGoToNextStep(
                result?.user?.email?.toLowerCase() || '',
                token
              );
            },
            onError: (error: any) => {
              console.error('Error signing in with Google', error);
              setOAuthError('Error signing in with Google');
            },
          }
        );
      })
      .catch((error) => {
        console.error('Error signing in with Google', error);
        setOAuthError('Error signing in with Google');
      });
  };

  const handleGoogleLoginFailure = (error: any) => {
    console.error('Failed to login with Google', error);
    setOAuthError('Failed to login with Google');
  };

  const saveEmailAndGoToNextStep = (email: string, token: string) => {
    dispatch(setCurrentAccount({ email }));
    if (offer?.code) {
      voucherRedeemMutate(
        { code: offer.code, token },
        {
          onSuccess: () => {
            if (offer?.free) {
              navigate(RedirectPaths[RedirectPathsEnum.DOWNLOAD_APP]);
            } else {
              navigate(RedirectPaths[RedirectPathsEnum.PAYMENT]);
            }
          },
          onError: (error: any) => {
            if (error?.response?.data?.type === 'VoucherExpiredError') {
              setAlreadyClaimed(true);
            } else {
              setError(
                error?.response?.data?.message ||
                  error?.message ||
                  'Something went wrong'
              );
            }
          },
        }
      );
    } else {
      if (offer?.free) {
        userConfirmationMutate(
          {
            membershipId: mileagePlus?.membershipId || '',
            surname: mileagePlus?.surname || '',
            token,
          },
          {
            onSuccess: () => {
              navigate(RedirectPaths[RedirectPathsEnum.DOWNLOAD_APP]);
            },
            onError: (error: any) => {
              console.error(error);
              setError(
                error?.response?.data?.message ||
                  error?.message ||
                  'Something went wrong'
              );
            },
          }
        );
      } else {
        checkUsageMutate(
          {
            voucherRuleId: offer?.voucherRuleId,
            token,
          },
          {
            onSuccess: () => {
              navigate(RedirectPaths[RedirectPathsEnum.PAYMENT]);
            },
            onError: (error: any) => {
              console.error(error);
              setError(
                error?.response?.data?.message ||
                  error?.message ||
                  'Something went wrong'
              );
              if (
                error?.response?.data?.message &&
                error.response.data.message === 'Voucher is already used'
              ) {
                setAlreadyClaimed(true);
              }
            },
          }
        );
      }
    }
  };

  const handleSubmit = (
    values: SignUpFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    return new Promise((resolve, reject) => {
      const email = values.email.toLowerCase();
      // Wrap mutate in a Promise
      mutate(
        { ...values, email },
        {
          onSuccess: (data: any) => {
            saveEmailAndGoToNextStep(email, data.token);
            resolve(data); // Resolve the promise on success
          },
          onError: (error: any) => {
            if (error?.response?.data?.type === 'EmailAlreadyExistsError') {
              const errorMessage =
                'An account with this email address already exists';
              setError(errorMessage);
              reject(error);
            } else {
              setError(error?.response?.data?.message);
              reject(error?.response?.data?.message);
            }
          },
        }
      );
    });
  };

  const titleContent = (
    <Box textAlign="center">
      <Text fontSize="2xl" fontWeight="500" mb="4">
        Create account
      </Text>
      <Text fontSize="md" fontWeight="400">
        Already a user?{' '}
        <Link as={RouterLink} to={RedirectPaths[RedirectPathsEnum.SIGNIN]}>
          Sign in
        </Link>
      </Text>
    </Box>
  );

  const mainContent = (
    <>
      <Flex gap="3" flexDirection={{ base: 'column', md: 'row' }}>
        <AppleSignin
          authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID || '',
            scope: 'email name',
            redirectURI: process.env.REACT_APP_APPLE_RETURN_URL + 'signin',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="dark"
          render={(renderProps: any) => (
            <Button
              variant="whiteButton"
              w="full"
              size="sm"
              onClick={renderProps.onClick}
              leftIcon={<Box as={AppleLogo} boxSize="22px" mb="1" />}
            >
              Continue with Apple
            </Button>
          )}
          onSuccess={handleAppleLoginSuccess}
          onError={handleAppleLoginFailure}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
          render={(renderProps) => (
            <Button
              variant="whiteButton"
              w="full"
              size="sm"
              onClick={renderProps.onClick}
              leftIcon={<Box as={GoogleLogo} boxSize="20px" />}
            >
              Continue with Google
            </Button>
          )}
          buttonText="Google Sign In"
          onSuccess={handleGoogleLoginSuccess}
          onFailure={handleGoogleLoginFailure}
          cookiePolicy={'single_host_origin'}
        />{' '}
      </Flex>
      {oauthError && (
        <div>
          <Text
            color="error.500"
            fontSize="md"
            mt="5"
            maxWidth={{ base: '100%', sm: '300px' }}
            mx="auto"
            textAlign="center"
          >
            {oauthError}
          </Text>
        </div>
      )}
      <TextDivider text="or" />
      <SignUpForm onSubmit={handleSubmit} />
      {error && (
        <div>
          <Text
            color="error.500"
            fontSize="md"
            mt="5"
            maxWidth={{ base: '100%', sm: '300px' }}
            mx="auto"
            textAlign="center"
          >
            {error}
          </Text>
        </div>
      )}
    </>
  );

  const asideContent = offer && (
    <Box textAlign="center">
      <ChakraImage
        src={Image}
        alt="Screenshots from the Jet lag app"
        w="100%"
        maxW="300px"
        mx="auto"
        mb="4"
      />
      <Text fontSize="lg" fontWeight="500">
        Make jet lag history
      </Text>
      <Text fontSize="lg" fontWeight="500">
        {offer?.voucherRuleId === 'ecd786a8-83af-499a-82f1-b8c4f40aca5c'
          ? 'Lifetime subscription'
          : offer.type === 'subscription'
            ? '1-year subscription'
            : offer.type === 'credit'
              ? 'Free jet lag plan'
              : offer.type}
      </Text>
      {!offer.free && (
        <Text fontSize="md" fontWeight="400">
          {offer.discount && descriptionSplit ? (
            <span>
              {descriptionSplit[1]}
              <span style={{ textDecoration: 'line-through' }}>
                {descriptionSplit[2]}
              </span>
              {descriptionSplit[3]}
            </span>
          ) : (
            offer.description
          )}
        </Text>
      )}
    </Box>
  );

  return (
    <>
      <Nav variant="default" />
      <Main>
        {!alreadyClaimed && (
          <ContentWithSidebarLayout
            titleContent={titleContent}
            mainContent={mainContent}
            asideContent={asideContent}
            hideAsideOnMobile
          />
        )}
        {alreadyClaimed && <AlreadyClaimedOffer />}
      </Main>
    </>
  );
};
