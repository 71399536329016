import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { themeColors } from '@/styles/theme';

interface TextDividerProps {
  text?: string;
}

export const TextDivider: React.FC<TextDividerProps> = ({ text }) => {
  return (
    <Flex align="center" justify="center" my="6">
      <Box bg={themeColors.gray} height="1px" width="60px" rounded="full" />
      <Text mx="2" fontWeight="400" fontSize="md" textAlign="center">
        {text}
      </Text>
      <Box bg={themeColors.gray} height="1px" width="60px" rounded="full" />
    </Flex>
  );
};
