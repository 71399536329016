import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  HStack,
} from '@chakra-ui/react';
import { signInSchema } from './validations';

export type SignInFormValues = {
  email: string;
  password: string;
};

type SignInFormProps = {
  onSubmit: (
    data: SignInFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => Promise<unknown>;
};

export const SignInForm: React.FC<SignInFormProps> = ({ onSubmit }) => {
  const initialValues: SignInFormValues = {
    email: '',
    password: '',
  };

  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={(values, { setSubmitting }) => {
        setError(undefined);
        setSubmitting(true);

        onSubmit(values, setError).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {({
        isSubmitting,
        isValid,
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
      }) => (
        <Box as={Form} mt="3" width="100%" maxWidth="440px">
          <HStack
            spacing="3"
            alignItems="flex-start"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <FormControl
              id="email"
              isInvalid={!!touched.email && !!errors.email}
            >
              <FormLabel>Email</FormLabel>
              <Box>
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage fontSize="md">
                  {errors.email}
                </FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl
              id="password"
              isInvalid={
                !!touched.password &&
                !!errors.password &&
                values.password.length < 6
              }
            >
              <FormLabel>Password</FormLabel>
              <Box>
                <Input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-password"
                />
                <FormErrorMessage fontSize="md">
                  {errors.password}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </HStack>
          {error && (
            <Text
              color="error.500"
              fontSize="md"
              mt="5"
              maxWidth={{ base: '100%', sm: '300px' }}
              mx="auto"
              textAlign="center"
            >
              {error}
            </Text>
          )}
          <Button
            colorScheme="orange"
            width="100%"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !isValid}
            mt="5"
            fontWeight="500"
          >
            Next
          </Button>
        </Box>
      )}
    </Formik>
  );
};
