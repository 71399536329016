import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = 'https://shift-work-api.timeshifter.com/';

export const ruleRedeemCode = async ({ code }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseURL}v2/rules/redeem/codes/${code}`
    );
    return data;
  } catch (error) {
    console.error('Error during my circadian day redeem code:', error);
    throw error;
  }
};

export const ruleRedeemInvite = async ({ code }) => {
  try {
    const { data } = await axios.get(
      `${apiBaseURL}v2/rules/redeem/invites/${code}`
    );
    return data;
  } catch (error) {
    console.error('Error during my circadian day redeem invite:', error);
    throw error;
  }
};

export const createPlan = async ({
  firstName,
  lastName,
  age,
  gender,
  normalSleepTime,
  normalWakeTime,
  chronotype,
  email,
  ruleCode,
}) => {
  try {
    const { data } = await axios.post(
      `${apiBaseURL}v1/mycircadian/create-plan `,
      {
        firstName,
        lastName,
        age,
        gender,
        normalSleepTime,
        normalWakeTime,
        chronotype,
        email,
        ruleCode,
      }
    );
    return data;
  } catch (error) {
    console.error('Error during my circadian day create plan:', error);
    throw error;
  }
};

export const getPlan = async ({ planNumber }) => {
  try {
    const response = await axios.get(
      `${apiBaseURL}v1/mycircadian/download?planNumber=${planNumber}`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const isMobileSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      ('ontouchstart' in window || navigator.maxTouchPoints > 0);

    if (isMobileSafari) {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `Plan-${planNumber}.pdf`; // Set filename
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  } catch (error) {
    console.error('Error during my circadian day get plan:', error);
    throw error;
  }
};
