import { Offer, OfferState } from '@/utils/types/offer';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';

const initialState: OfferState = {
  offer: null,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOffer(state, action: PayloadAction<Offer>) {
      state.offer = action.payload;
    },
    clearOffer(state) {
      state.offer = null;
    },
  },
});

// Export selector
export const selectOffer = createSelector(
  (state: AppState) => state.offer,
  (offerState) => offerState.offer
);

// Export actions
export const { setOffer, clearOffer } = offerSlice.actions;

// Export reducer
export default offerSlice.reducer;
