import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image as ChakraImage, Text } from '@chakra-ui/react';

import Image from '@/assets/images/claim-coupon.svg';
import { Main, Nav } from '@/components/layout';
import {
  MileagePlusVerificationForm,
  MileagePlusVerificationFormValues,
} from '@/components/forms';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { useUserVerification } from '@/hooks/useMileagePlus';
import { setOffer } from '@/store/offer/offer.slice';
import { useAppDispatch } from '@/store';
import { setMileagePlus } from '@/store/mileagePlus/mileage-plus.slice';
import { Offer } from '@/utils/types/offer';
import { AlreadyClaimedOffer } from '@/components/offers';

import { nonMemberOffer } from '@/utils/constants/offers';

export const MileagePlusPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutate } = useUserVerification();
  const [alreadyClaimed, setAlreadyClaimed] = useState<boolean>(false);

  const checkNonMember = () => {
    dispatch(setOffer(nonMemberOffer('mileagePlus')));
    navigate(RedirectPaths[RedirectPathsEnum.MILEAGE_PLUS_OFFER]);
  };

  useEffect(() => {
    const isNonMember = location.hash === '#non-member';

    if (isNonMember) {
      checkNonMember();
    }
  }, [location]);

  useEffect(() => {
    document.title = 'MileagePlus | Timeshifter®';
  }, []);

  const handleSubmit = async (data: MileagePlusVerificationFormValues) => {
    return new Promise<void>((resolve, reject) => {
      mutate(data, {
        onSuccess: (response: Offer) => {
          dispatch(setMileagePlus(data));

          dispatch(
            setOffer({
              type: response.type || '',
              description: response.description || '',
              authCode: response.authCode || '',
              voucherRuleId: response.voucherRuleId || '',
              name: response.name || '',
              provider: 'mileagePlus',
              free: response.free,
              membershipId: data.membershipId || '',
            })
          );
          navigate(RedirectPaths[RedirectPathsEnum.MILEAGE_PLUS_OFFER]);
          resolve();
        },
        onError: (error: any) => {
          if (error?.response?.data?.message === 'Offer already used') {
            setAlreadyClaimed(true);
          } else {
            const message = error?.response?.data?.message;
            reject(
              message && message !== 'Internal server error'
                ? message
                : 'Something went wrong! Please check your MileagePlus number and last name.'
            );
          }
        },
      });
    });
  };

  return (
    <>
      <Nav variant="blue" />
      <Main>
        {!alreadyClaimed && (
          <>
            <>
              <ChakraImage
                src={Image}
                alt="Mileage Plus Offer redeem image"
                width="250px"
                my="6"
              />
              <Text fontSize="2xl" fontWeight="500" mb="4" textAlign="center">
                Verify your membership
              </Text>
            </>
            <MileagePlusVerificationForm onSubmit={handleSubmit} />
          </>
        )}
        {alreadyClaimed && <AlreadyClaimedOffer />}
      </Main>
    </>
  );
};
