// import { processUrl } from '@/utils/helpers/url.helper';

export const AppRoutesPaths = {
  REDEEM_ROUTE_CODE: '/offer/:code',
  REDEEM_ROUTE: '/',
  OFFER_ROUTE: '/offer',
  MILES_MORE_ROUTE: '/miles-and-more',
  MILEAGE_PLUS_ROUTE: '/mileageplus',
  MILES_MORE_OFFER_ROUTE: '/miles-and-more/offer',
  MILEAGE_PLUS_OFFER_ROUTE: '/mileageplus/offer',
  SIGNIN_ROUTE: '/sign-in',
  SIGNUP_ROUTE: '/create-account',
  PAYMENT_ROUTE: '/payment',
  DOWNLOAD_APP_ROUTE: '/success',
  VERIFY_EMAIL_ROUTE: '/verify-email/:code',
  MY_CIRCADIAN_DAY_ROUTE: '/my-circadian-day',
  MY_CIRCADIAN_DAY_ROUTE_CODE: '/my-circadian-day/create-plan/:code',
  MY_CIRCADIAN_DAY_CREATE_PLAN_ROUTE: '/my-circadian-day/create-plan',
  MY_CIRCADIAN_DAY_SUCCESS_ROUTE: '/my-circadian-day/success',

  NOT_FOUND_ROUTE: '/404',
};

export const RedirectPathsEnum = {
  REDEEM: 'REDEEM',
  OFFER: 'OFFER',
  MILES_MORE: 'MILES_MORE',
  MILES_MORE_OFFER: 'MILES_MORE_OFFER',
  MILEAGE_PLUS: 'MILEAGE_PLUS',
  MILEAGE_PLUS_OFFER: 'MILEAGE_PLUS_OFFER',
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  PAYMENT: 'PAYMENT',
  DOWNLOAD_APP: 'DOWNLOAD_APP',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  MY_CIRCADIAN_DAY: 'MY_CIRCADIAN_DAY',
  MY_CIRCADIAN_DAY_CREATE_PLAN: 'MY_CIRCADIAN_DAY_CREATE_PLAN',
  MY_CIRCADIAN_DAY_SUCCESS: 'MY_CIRCADIAN_DAY_SUCCESS',

  NOT_FOUND: 'NOT_FOUND',
};

export const RedirectPaths = {
  // [RedirectPathsEnum.REDEEM]: (code: string) =>
  //   processUrl(AppRoutesPaths.REDEEM_ROUTE, { code }),
  [RedirectPathsEnum.REDEEM]: AppRoutesPaths.REDEEM_ROUTE,
  [RedirectPathsEnum.OFFER]: AppRoutesPaths.OFFER_ROUTE,
  [RedirectPathsEnum.MILES_MORE]: AppRoutesPaths.MILES_MORE_ROUTE,
  [RedirectPathsEnum.MILES_MORE_OFFER]: AppRoutesPaths.MILES_MORE_OFFER_ROUTE,
  [RedirectPathsEnum.MILEAGE_PLUS]: AppRoutesPaths.MILEAGE_PLUS_ROUTE,
  [RedirectPathsEnum.MILEAGE_PLUS_OFFER]:
    AppRoutesPaths.MILEAGE_PLUS_OFFER_ROUTE,
  [RedirectPathsEnum.SIGNIN]: AppRoutesPaths.SIGNIN_ROUTE,
  [RedirectPathsEnum.SIGNUP]: AppRoutesPaths.SIGNUP_ROUTE,
  [RedirectPathsEnum.PAYMENT]: AppRoutesPaths.PAYMENT_ROUTE,
  [RedirectPathsEnum.DOWNLOAD_APP]: AppRoutesPaths.DOWNLOAD_APP_ROUTE,
  [RedirectPathsEnum.VERIFY_EMAIL]: AppRoutesPaths.VERIFY_EMAIL_ROUTE,
  [RedirectPathsEnum.MY_CIRCADIAN_DAY]: AppRoutesPaths.MY_CIRCADIAN_DAY_ROUTE,
  [RedirectPathsEnum.MY_CIRCADIAN_DAY_CREATE_PLAN]:
    AppRoutesPaths.MY_CIRCADIAN_DAY_CREATE_PLAN_ROUTE,
  [RedirectPathsEnum.MY_CIRCADIAN_DAY_SUCCESS]:
    AppRoutesPaths.MY_CIRCADIAN_DAY_SUCCESS_ROUTE,

  [RedirectPathsEnum.NOT_FOUND]: AppRoutesPaths.NOT_FOUND_ROUTE,
};
