import { useMutation } from 'react-query';
import { userVerification, userConfirmation } from '@/api/mileage-plus';

export const useUserVerification = () => {
  return useMutation(userVerification);
};

export const useUserConfirmation = () => {
  return useMutation(userConfirmation);
};
