import React, { useState, useEffect, useRef } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Text, Spinner } from '@chakra-ui/react';
import { TextDivider } from '@/components/shared';
import './PayWithCardForm.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
const CHECK_RETRIEVE_PAYMENT_INTERVAL = 3000;

const CardPaymentFormInner = ({
  clientSecret,
  offer,
  checkStatus,
  navigateSuccess,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const isValid = true;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const expressCheckoutRef = useRef<HTMLDivElement>(null);
  const [hasPaymentMethods, setHasPaymentMethods] = useState(false);

  useEffect(() => {
    if (checkStatus) {
      setLoading(true);

      if (!stripe) {
        return;
      }

      const intervalId = setInterval(() => {
        stripe
          .retrievePaymentIntent(clientSecret)
          .then(({ paymentIntent }) => {
            switch (paymentIntent?.status) {
              case 'succeeded':
                clearInterval(intervalId);
                navigateSuccess();
                break;
              case 'processing':
                break;
              case 'requires_payment_method':
                clearInterval(intervalId);
                setLoading(false);
                setError('Payment failed. Please try another payment method.');
                break;
              default:
                clearInterval(intervalId);
                setLoading(false);
                setError('Something went wrong.');
                break;
            }
          })
          .catch((error) => {
            clearInterval(intervalId);
            setLoading(false);
            setError(`Error retrieving payment intent: ${error.message}`);
          });
      }, CHECK_RETRIEVE_PAYMENT_INTERVAL);
    } else {
      if (!stripe || !elements) {
        return;
      }

      const element = elements.getElement(ExpressCheckoutElement);

      (element as any).on('ready', () => {
        setTimeout(() => checkPaymentMethods(), 1000);
      });
    }
  }, [stripe, clientSecret, checkStatus]);

  const checkPaymentMethods = () => {
    // A hacky way, but we don't get any information
    // from ExpressCheckout about available payment methods,
    // so we had to improvise.
    if (
      expressCheckoutRef.current &&
      expressCheckoutRef.current.clientHeight > 10
    ) {
      setHasPaymentMethods(true);
    } else {
      setHasPaymentMethods(false);
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    confirm();
  };

  const confirm = async () => {
    if (!stripe || !elements) {
      setError('Stripe has not loaded');
      return;
    }

    try {
      await elements.submit();
      setLoading(true);
      setError('');

      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url:
            window.location.href +
            `?offer=${encodeURIComponent(JSON.stringify(offer) || '')}`,
        },
      });

      if (result.error) {
        console.error('Payment Error:', result.error.message);
        setError(result.error.message);
      }
    } catch (e) {
      setLoading(false);
      console.error('Payment failure:', error);
      setError('Payment processing failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading || !checkStatus ? (
        <form onSubmit={handleFormSubmit} className="paymentForm">
          <div ref={expressCheckoutRef}>
            <ExpressCheckoutElement onConfirm={confirm} />
          </div>

          {hasPaymentMethods && <TextDivider text="Or, pay with card" />}

          <PaymentElement />
          <Button
            colorScheme="orange"
            width="100%"
            type="submit"
            isLoading={loading}
            isDisabled={loading && !isValid}
            mt="5"
            fontWeight="500"
          >
            Buy
          </Button>
          {error && (
            <Text
              color="error.500"
              fontSize="md"
              mt="5"
              maxWidth={{ base: '100%', sm: '300px' }}
              mx="auto"
              textAlign="center"
            >
              {error}
            </Text>
          )}
        </form>
      ) : (
        <div
          style={{ width: '440px', display: 'flex', justifyContent: 'center' }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export const CardPaymentForm: React.FC<any> = (props) => (
  <Elements
    stripe={stripePromise}
    options={{
      clientSecret: props.clientSecret,
    }}
  >
    <CardPaymentFormInner {...props} />
  </Elements>
);
