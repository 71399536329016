import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Text,
  Image as ChakraImage,
  Spinner,
} from '@chakra-ui/react';
import Image from '@/assets/images/jetlag-half.png';
import { ContentWithSidebarLayout, Main, Nav } from '@/components/layout';
import { CardPaymentForm } from '@/components/forms';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { selectOffer } from '@/store/offer/offer.slice';
import { AppState } from '@/store';
import { usePaymentSubscriptions } from '@/hooks/usePayment';
import { selectCurrentAccount } from '@/store/account/current-account.slice';
import { TIMESHIFTER_REDIRECT_URL } from '@/utils/constants/redirects';
import { useAppDispatch } from '@/store';
import { setOffer } from '@/store/offer/offer.slice';
import { splitDescription } from '@/utils/helpers/string.helper';

export const PaymentPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const offer = useSelector((state: AppState) => selectOffer(state));
  const currentAccount = useSelector((state: AppState) =>
    selectCurrentAccount(state)
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  );
  const [checkStatus, setCheckStatus] = useState<boolean>(false);
  const { mutate } = usePaymentSubscriptions();

  useEffect(() => {
    document.title = 'Payment information | Timeshifter®';
  }, []);

  const descriptionSplit = offer?.discount
    ? splitDescription(offer?.description || '')
    : '';

  const getClientSecret = () => {
    setLoading(true);

    try {
      mutate(
        {
          email: currentAccount?.email.toLowerCase(),
          type: offer?.provider,
          ruleId: offer?.voucherRuleId,
          authCode: offer?.authCode,
          mmCardNumber: offer?.mmCardNumber,
          membershipId: offer?.membershipId,
        },
        {
          onSuccess: (data: any) => {
            setLoading(false);
            setClientSecret(data.clientSecret);
          },
          onError: (error: any) => {
            setLoading(false);
            console.error(error);
          },
        }
      );
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stripeRedirected = queryParams.get('redirect_status');
    const secret = queryParams.get('payment_intent_client_secret') || '';

    if (!stripeRedirected && !offer) {
      window.location.href = TIMESHIFTER_REDIRECT_URL;
    } else if (offer && !stripeRedirected) {
      getClientSecret();
    } else if (!offer && stripeRedirected) {
      setClientSecret(secret);
      setCheckStatus(true);
      const queryParamOffer = JSON.parse(
        decodeURIComponent(queryParams.get('offer') || '')
      );
      dispatch(
        setOffer({
          type: queryParamOffer.type || '',
          description: queryParamOffer.description || '',
          name: queryParamOffer.name || '',
        })
      );
    }
  }, [offer]);

  const navigateSuccess = () => {
    navigate(RedirectPaths[RedirectPathsEnum.DOWNLOAD_APP]);
  };

  const titleContent = (
    <Box textAlign="center">
      <Text fontSize="2xl" fontWeight="500" mb="4">
        Payment details
      </Text>
    </Box>
  );

  const mainContent = !loading ? (
    clientSecret ? (
      <CardPaymentForm
        checkStatus={checkStatus}
        clientSecret={clientSecret}
        navigateSuccess={navigateSuccess}
        offer={{
          name: offer?.name,
          type: offer?.type,
          description: offer?.description,
        }}
      />
    ) : (
      <>
        <Text
          color="error.500"
          fontSize="md"
          mt="5"
          width={'400px'}
          mx="auto"
          textAlign="center"
        >
          Something went wrong
        </Text>
        <Button
          colorScheme="orange"
          width="100%"
          onClick={getClientSecret}
          isLoading={loading}
          mt="5"
          fontWeight="500"
        >
          Try again
        </Button>
      </>
    )
  ) : (
    <div style={{ width: '440px', display: 'flex', justifyContent: 'center' }}>
      <Spinner />
    </div>
  );

  const asideContent = offer && (
    <Box textAlign="center">
      <ChakraImage
        src={Image}
        alt="Screenshots from the Jet lag app"
        w="100%"
        maxW="300px"
        mx="auto"
        mb="4"
      />
      <Text fontSize="lg" fontWeight="500">
        Make jet lag history
      </Text>
      <Text fontSize="lg" fontWeight="500">
        {offer?.voucherRuleId === 'ecd786a8-83af-499a-82f1-b8c4f40aca5c'
          ? 'Lifetime subscription'
          : offer.type === 'subscription'
            ? '1-year subscription'
            : offer.type === 'credit'
              ? 'Free jet lag plan'
              : offer.type}
      </Text>
      {!offer.free && (
        <Text fontSize="md" fontWeight="400">
          {offer.discount && descriptionSplit ? (
            <span>
              {descriptionSplit[1]}
              <span style={{ textDecoration: 'line-through' }}>
                {descriptionSplit[2]}
              </span>
              {descriptionSplit[3]}
            </span>
          ) : (
            offer.description
          )}
        </Text>
      )}
    </Box>
  );

  return (
    <>
      <Nav variant="default" />
      <Main>
        <ContentWithSidebarLayout
          titleContent={titleContent}
          mainContent={mainContent}
          asideContent={asideContent}
        />
      </Main>
    </>
  );
};
