import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { mileagePlusVerificationSchema } from './validations';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  HStack,
} from '@chakra-ui/react';

export type MileagePlusVerificationFormValues = {
  membershipId: string;
  surname: string;
};

type VerificationFormProps = {
  onSubmit: (
    data: MileagePlusVerificationFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => Promise<void>;
};

export const MileagePlusVerificationForm: React.FC<VerificationFormProps> = ({
  onSubmit,
}) => {
  const initialValues: MileagePlusVerificationFormValues = {
    membershipId: '',
    surname: '',
  };

  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={mileagePlusVerificationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setError(undefined);
        setSubmitting(true);
        onSubmit(values, setError)
          .catch((error: string) => {
            setError(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        isSubmitting,
        isValid,
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
      }) => (
        <Box as={Form} mt="3" width="100%" maxWidth="440px">
          {error && (
            <Text
              color="error.500"
              fontSize="md"
              mb="6"
              maxWidth={{ base: '100%', sm: '300px' }}
              mx="auto"
              textAlign="center"
            >
              {error}
            </Text>
          )}
          <HStack
            spacing="3"
            alignItems="flex-start"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <FormControl
              id="membershipId"
              isInvalid={
                !!error || (!!touched.membershipId && !!errors.membershipId)
              }
            >
              <FormLabel>Your MileagePlus number</FormLabel>
              <Input
                name="membershipId"
                value={values.membershipId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormErrorMessage fontSize="md">
                {errors.membershipId}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="surname"
              isInvalid={!!error || (!!touched.surname && !!errors.surname)}
            >
              <FormLabel>Last name</FormLabel>
              <Input
                name="surname"
                value={values.surname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormErrorMessage fontSize="md">
                {errors.surname}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <Button
            colorScheme="orange"
            width="100%"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !isValid}
            mt="4"
            fontWeight="500"
          >
            Verify
          </Button>
        </Box>
      )}
    </Formik>
  );
};
