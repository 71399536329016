import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Main, Nav } from '@/components/layout';
import { Box, Button, Text, Image as ChakraImage } from '@chakra-ui/react';

import Image from '@/assets/images/circadian-day.png';
import ImageLogo from '@/assets/images/circadian-day-logo.png';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { useGetPlan } from '@/hooks/useRuleRedeem';

export const MyCircadianDaySuccessPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { mutate } = useGetPlan();
  const planNumber = sessionStorage.getItem('planNumber');

  useEffect(() => {
    if (!planNumber) {
      navigate(RedirectPaths[RedirectPathsEnum.MY_CIRCADIAN_DAY]);
    }

    document.title = 'Success | Timeshifter®';
  }, []);

  const download = () => {
    setError('');
    setLoading(true);

    mutate(
      { planNumber },
      {
        onSuccess: () => {
          setLoading(false);
          sessionStorage.removeItem('planNumber');
        },
        onError: () => {
          setLoading(false);
          setError('Something went wrong! Please try again.');
        },
      }
    );
  };

  return (
    <>
      <Nav variant="default" />
      <Main>
        <Box>
          <div style={{ padding: '18px' }}>
            <Text
              fontSize="22"
              lineHeight="1.36"
              width="200px"
              textAlign="center"
              mt="6"
              mb="2.5"
              mx="auto"
              fontWeight="500"
            >
              Success!
            </Text>
            <Text
              fontSize="16"
              lineHeight="1.36"
              maxWidth="500px"
              textAlign="center"
              mb="22px"
              mx="auto"
            >
              Your plan is now ready. Download it now <br />
              or find it in your inbox at your convenience.
            </Text>
            <div style={{ display: 'flex' }}>
              <Button
                colorScheme="orange"
                width="200px"
                fontWeight="500"
                mx="auto"
                onClick={download}
                isLoading={loading}
              >
                Download
              </Button>
            </div>
            {error && (
              <Text
                color="error.500"
                fontSize="md"
                mb="6"
                maxWidth={{ base: '100%', sm: '300px' }}
                mx="auto"
                textAlign="center"
              >
                {error}
              </Text>
            )}
            <ChakraImage
              src={Image}
              alt="My Circadian Day Success image"
              mb="6"
              mt="12"
            />
            <ChakraImage
              src={ImageLogo}
              alt="My Circadian Day Success image"
              width="275px"
              mt="8"
              mx="auto"
            />
          </div>
        </Box>
      </Main>
    </>
  );
};
