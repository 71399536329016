type OfferType = 'mileagePlus' | 'M&M';

export const nonMemberOffer = (type: OfferType) => ({
  type: 'subscription',
  name: 'milesMore',
  description: 'Buy $24.99',
  provider: type,
  voucherRuleId:
    type === 'mileagePlus' ? mileagePlus : type === 'M&M' ? milesAndMore : '',
});

const mileagePlus = 'fe885208-ea89-46a5-add5-f070f4ffabdc';
const milesAndMore = '7931d89a-4d2a-45e1-b967-1a1eb2383b3d';
