import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { themeFonts } from '@/styles/theme';

export const MainLayout: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      minWidth="100%"
      bg="background.default"
    >
      <Outlet />
      <Box as="footer" py="1" px="3">
        <Text
          textAlign="center"
          fontFamily={themeFonts.avenir}
          fontWeight="500"
          fontSize="10px"
          color="secondary.500"
        >
          © 2024 Timeshifter Inc.
        </Text>
      </Box>
    </Box>
  );
};
