import { useMutation } from 'react-query';
import {
  voucherTest,
  generalRedeem,
  voucherRedeem,
  checkUsage,
} from '@/api/voucher';

export const useVoucherTest = () => {
  return useMutation(voucherTest);
};

export const useGeneralRedeem = () => {
  return useMutation(generalRedeem);
};

export const useVoucherRedeem = () => {
  return useMutation(voucherRedeem);
};

export const useCheckUsage = () => {
  return useMutation(checkUsage);
};
