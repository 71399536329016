import React from 'react';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import TimeshifterLogo from '@/assets/images/logo-color.svg';
import TimeshifterLogoWhite from '@/assets/images/logo-white.svg';
import MilesLogo from '@/assets/images/miles&more-logo.png';
import MileageLogo from '@/assets/images/mileage-plus-logo.png';
import { themeColors } from '@/styles/theme';

type StyledNavProps = {
  variant?: 'default' | 'blue' | 'white';
};

const variantStyles = {
  default: {
    background: 'transparent',
    separator: 'transparent',
  },
  blue: {
    background: themeColors.blueDark,
    separator: themeColors.white,
  },
  white: {
    background: themeColors.white,
    separator: themeColors.black,
  },
};

const StyledSeparator: React.FC<StyledNavProps & { mx?: number }> = ({
  variant = 'default',
  mx = 3,
}) => (
  <Box
    width="2px"
    height="40px"
    bg={variantStyles[variant].separator}
    opacity="0.3"
    mx={mx}
  />
);

const StyledNav: React.FC<StyledNavProps & { children: React.ReactNode }> = ({
  variant = 'default',
  children,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      as="nav"
      height="56px"
      bg={variantStyles[variant].background}
      display="flex"
      px="8"
      justifyContent={
        isMobile || variant !== 'default' ? 'center' : 'flex-start'
      }
      alignItems="center"
    >
      {children}
    </Box>
  );
};

const DefaultNav = () => (
  <Image src={TimeshifterLogo} alt="Timeshifter logo" width="190px" />
);

const WhiteNav = () => (
  <>
    <Image src={TimeshifterLogo} alt="Timeshifter logo" width="150px" />
    <StyledSeparator variant="white" mx={3} />
    <Image src={MilesLogo} alt="Miles & More logo" width="140px" />
  </>
);

const BlueNav = () => (
  <>
    <Image src={TimeshifterLogoWhite} alt="Timeshifter logo" width="150px" />
    <StyledSeparator variant="blue" mx={4} />
    <Image src={MileageLogo} alt="Mileage Plus logo" width="100px" />
  </>
);

export const Nav: React.FC<StyledNavProps> = ({ variant = 'default' }) => {
  const selectContent = () => {
    switch (variant) {
      case 'blue':
        return <BlueNav />;
      case 'white':
        return <WhiteNav />;
      default:
        return <DefaultNav />;
    }
  };

  return <StyledNav variant={variant}>{selectContent()}</StyledNav>;
};
