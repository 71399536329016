import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = process.env.REACT_APP_AUTH_BACKEND;
// eslint-disable-next-line no-undef
const appleApiBaseURL = process.env.REACT_APP_BACKEND;

function getDeviceInfo() {
  return {
    deviceName: navigator.userAgent,
    devicePlatform: 'web',
  };
}

export const signIn = async ({ email, password }) => {
  try {
    const deviceInfo = getDeviceInfo();
    const { data } = await axios.post(`${apiBaseURL}/signin`, {
      email,
      pass: password,
      ...deviceInfo,
    });
    return data;
  } catch (error) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

export const signUp = async ({ email, password }) => {
  try {
    const deviceInfo = getDeviceInfo();
    const { data } = await axios.post(`${apiBaseURL}/signup`, {
      email,
      pass: password,
      ...deviceInfo,
    });
    return data;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

export const appleSignIn = async ({ appleIdToken }) => {
  try {
    const deviceInfo = getDeviceInfo();
    const { data } = await axios.post(`${appleApiBaseURL}/apple-auth`, {
      appleIdToken,
      ...deviceInfo,
    });
    return data;
  } catch (error) {
    console.error('Error during apple sign in:', error);
    throw error;
  }
};

export const googleSignIn = async ({ googleIdToken }) => {
  try {
    const deviceInfo = getDeviceInfo();
    const { data } = await axios.post(`${apiBaseURL}/google-auth`, {
      googleIdToken,
      ...deviceInfo,
    });
    return data;
  } catch (error) {
    console.error('Error during google sign in:', error);
    throw error;
  }
};

export const verifyEmail = async (value) => {
  try {
    await axios.post(`${apiBaseURL}/email/verify`, {
      token: value,
    });
  } catch (error) {
    console.error('Error during email verify:', error);
    throw error;
  }
};
