import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import { Main, Nav } from '@/components/layout';
import { ValidOffer } from '@/components/offers';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { AppState } from '@/store';
import { selectOffer } from '@/store/offer/offer.slice';

export const MilesMoreOfferPage = () => {
  const navigate = useNavigate();
  const offer = useSelector((state: AppState) => selectOffer(state));

  useEffect(() => {
    if (!offer) {
      navigate(RedirectPaths[RedirectPathsEnum.MILES_MORE]);
    }
  }, [offer, navigate]);

  useEffect(() => {
    document.title = 'Miles & More offer | Timeshifter®';
  }, []);

  return (
    <>
      <Nav variant="white" />
      <Main>
        {offer && (
          <ValidOffer
            subTitle={
              offer.type === 'subscription'
                ? '1-year subscription'
                : offer.type === 'credit'
                  ? 'Free jet lag plan'
                  : offer.type
            }
          >
            <Button
              onClick={() => navigate(RedirectPaths[RedirectPathsEnum.SIGNUP])}
              colorScheme="orange"
              fontWeight="500"
            >
              {offer.description}
            </Button>
          </ValidOffer>
        )}
      </Main>
    </>
  );
};
