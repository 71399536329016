import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Image as ChakraImage,
} from '@chakra-ui/react';
import { couponClaimSchema } from './validations';
import { useRuleCodeRedeem, useRuleInviteRedeem } from '@/hooks/useRuleRedeem';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import Image from '@/assets/images/circadian-day-fadeout.png';

export const MyCircadianDayRedeemForm: React.FC = () => {
  const initialValues = { code: '' };
  const navigate = useNavigate();
  const { mutate } = useRuleCodeRedeem();
  const { mutate: inviteMutate } = useRuleInviteRedeem();
  const [error, setError] = useState<string>('');
  const location = useLocation();
  const { code } = useParams();

  return (
    <div style={{ padding: '18px' }}>
      <ChakraImage
        src={Image}
        alt="My Circadian Day Redeem image"
        width="340px"
        mt="6"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={couponClaimSchema}
        onSubmit={(values, { setSubmitting }) => {
          // A different endpoint, one for manually entering codes and one for coming from e-mail.
          const action = code ? inviteMutate : mutate;
          action(
            { code: values.code },
            {
              onSuccess: () => {
                setSubmitting(false);
                sessionStorage.setItem('ruleCode', values.code);
                navigate(
                  RedirectPaths[RedirectPathsEnum.MY_CIRCADIAN_DAY_CREATE_PLAN]
                );
              },
              onError: (error: any) => {
                setError(
                  error?.response?.data?.message ||
                    error?.message ||
                    'Something went wrong'
                );
                setSubmitting(false);
              },
            }
          );
        }}
      >
        {({
          setFieldValue,
          submitForm,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
        }) => {
          useEffect(() => {
            if (code) {
              setFieldValue('code', code).then(() => {
                // Delay the submission to ensure all form states are updated
                setTimeout(() => submitForm(), 100);
              });
            }
          }, [location.search, setFieldValue, submitForm]);

          return (
            <Box
              as={Form}
              mt="2"
              minWidth={{ base: '100%', sm: '254px' }}
              textAlign="center"
            >
              <Text
                fontSize="18"
                lineHeight="1.36"
                width="200px"
                textAlign="center"
                mt="23px"
                mb="23px"
                mx="auto"
                color="#333232"
                fontWeight="500"
              >
                My Circadian Day™
                <br /> Daily plan
              </Text>
              <FormControl
                id="code"
                isInvalid={!!error || (touched.code && !!errors.code)}
              >
                <Input
                  name="code"
                  value={values.code}
                  onChange={(value) => {
                    setError('');
                    handleChange(value);
                  }}
                  onBlur={handleBlur}
                  placeholder="Enter code"
                  width="250px"
                  textAlign="center"
                  _placeholder={{ textAlign: 'center', color: '#B9B5B6' }}
                />
                <FormErrorMessage
                  fontSize="md"
                  textAlign="center"
                  display="block"
                >
                  {errors.code}
                </FormErrorMessage>
                {error && (
                  <Text
                    color="error.500"
                    fontSize="md"
                    mx="auto"
                    maxWidth={{ base: '100%', sm: '300px' }}
                    textAlign="center"
                  >
                    {error}
                  </Text>
                )}
              </FormControl>
              <Button
                colorScheme="orange"
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !isValid}
                mt="5"
                fontWeight="500"
                width="250px"
                textAlign="center"
              >
                Next
              </Button>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};
