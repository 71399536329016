import { configureStore } from '@reduxjs/toolkit';
import currentAccountReducer from './account/current-account.slice';
import offerReducer from './offer/offer.slice';
import mileagePlusReducer from './mileagePlus/mileage-plus.slice';

export const store = configureStore({
  reducer: {
    currentAccount: currentAccountReducer,
    offer: offerReducer,
    mileagePlus: mileagePlusReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
