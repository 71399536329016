import React, { useEffect } from 'react';

import { CouponClaimForm } from '@/components/forms';
import { Main, Nav } from '@/components/layout';

export const RedeemPage = () => {
  useEffect(() => {
    document.title = 'Redeem offer | Timeshifter®';
  }, []);

  return (
    <>
      <Nav variant="default" />
      <Main>
        <CouponClaimForm />
      </Main>
    </>
  );
};
