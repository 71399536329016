import { object, ref, string, number } from 'yup';

export const signInSchema = () =>
  object({
    email: string()
      .email('Please enter a valid email')
      .required('The field is required'),
    password: string().min(6, '').required('The field is required'),
  });

export const signUpSchema = () =>
  object({
    email: string()
      .email('Please enter a valid email')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Please enter a valid email')
      .required('The field is required'),
    password: string().required('The field is required'),
    passwordRepeat: string()
      .min(6, '')
      .oneOf([ref('password')], 'Passwords don’t match. Please try again.')
      .required('The field is required'),
  });

export const createPlanSchema = () =>
  object({
    email: string()
      .email('Please enter a valid email')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Please enter a valid email')
      .required('The field is required'),
    firstName: string().required('The field is required'),
    lastName: string().required('The field is required'),
    chronotype: object({
      value: string().required('The field is required'),
    }),
    sleepTime: object({
      value: string().required('The field is required'),
    }),
    wakeTime: object({
      value: string().required('The field is required'),
    }),
    sex: object({
      value: string().required('The field is required'),
    }),
    age: number().required('The field is required'),
  });
