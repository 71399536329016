import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type MainProps = {
  children?: ReactNode;
};

export const Main: React.FC<MainProps> = ({ children }) => (
  <Box
    as="main"
    display="flex"
    flex="1"
    flexDirection="column"
    p="8"
    alignItems="center"
  >
    {children}
  </Box>
);
