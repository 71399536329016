import { MileagePlus, MileagePlusState } from '@/utils/types/mileagePlus';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';

const initialState: MileagePlusState = {
  mileagePlus: null,
};

const mileagePlusSlice = createSlice({
  name: 'mileagePlus',
  initialState,
  reducers: {
    setMileagePlus(state, action: PayloadAction<MileagePlus>) {
      state.mileagePlus = action.payload;
    },
    clearMileagePlus(state) {
      state.mileagePlus = null;
    },
  },
});

// Export selector
export const selectMileagePlus = createSelector(
  (state: AppState) => state.mileagePlus,
  (mileagePlusState) => mileagePlusState.mileagePlus
);

// Export actions
export const { setMileagePlus, clearMileagePlus } = mileagePlusSlice.actions;

// Export reducer
export default mileagePlusSlice.reducer;
