import { useMutation } from 'react-query';
import {
  ruleRedeemCode,
  ruleRedeemInvite,
  createPlan,
  getPlan,
} from '@/api/rule-redeem';

export const useRuleCodeRedeem = () => {
  return useMutation(ruleRedeemCode);
};

export const useRuleInviteRedeem = () => {
  return useMutation(ruleRedeemInvite);
};

export const useCreatePlan = () => {
  return useMutation(createPlan);
};

export const useGetPlan = () => {
  return useMutation(getPlan);
};
