import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image as ChakraImage, Text, Button, Icon } from '@chakra-ui/react';

import Image from '@/assets/images/claim-coupon.svg';
import { ReactComponent as IconSVG } from '@/assets/icons/hyperlink.svg';
import { Main, Nav } from '@/components/layout';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { useAppDispatch } from '@/store';
import { setOffer } from '@/store/offer/offer.slice';
import { useTokenExchange } from '@/hooks/useMilesAndMore';
import { nonMemberOffer } from '@/utils/constants/offers';
import { AlreadyClaimedOffer } from '@/components/offers';

export const MilesMorePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { mutate } = useTokenExchange();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState<boolean>(false);

  const milesAndMoreOAuth = process.env.REACT_APP_MILES_AND_MORE_OAUTH;

  const milesAndMoreLoginData = {
    response_type: 'code',
    scope:
      'urn:milesandmore:user:profile:v1:read urn:milesandmore:member:profile:v1:read', // process.env.REACT_APP_MILES_AND_MORE_SCOPE,
    redirect_uri: process.env.REACT_APP_MILES_AND_MORE_API_REDIRECT_URI,
    client_id: process.env.REACT_APP_MILES_AND_MORE_API_KEY,
  };

  useEffect(() => {
    document.title = 'Miles & More | Timeshifter®';
  }, []);

  useEffect(() => {
    const isNonMember = location.hash === '#non-member';
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    try {
      if (code) {
        let currency = undefined;

        const sessionStorageCurrency = sessionStorage.getItem('currency');
        if (sessionStorageCurrency) {
          currency =
            sessionStorageCurrency === 'null' ? null : sessionStorageCurrency;
          sessionStorage.removeItem('currency');
        }

        setIsSubmitting(true);
        mutate(
          { code, currency },
          {
            onSuccess: ({ data }: any) => {
              dispatch(
                setOffer({
                  type: data.type || '',
                  description: data.description || '',
                  voucherRuleId: data.voucherRuleId || '',
                  name: data.name || '',
                  provider: 'M&M',
                  mmCardNumber: data.mmCardNumber || '',
                })
              );
              navigate(RedirectPaths[RedirectPathsEnum.MILES_MORE_OFFER]);
            },
            onError: (error: any) => {
              setIsSubmitting(false);

              if (error?.response?.data?.message === 'Offer already used') {
                setAlreadyClaimed(true);
              } else {
                console.error(error);
                setError('Something went wrong');
              }
            },
          }
        );
      }
    } catch (e: any) {
      setIsSubmitting(false);
      console.error(e);
      setError('Something went wrong');
    }

    if (isNonMember) {
      checkNonMember();
    }
  }, [location]);

  const openMilesAndMoreLogin = () => {
    window.location.href = `${milesAndMoreOAuth}?response_type=${milesAndMoreLoginData.response_type}&scope=${milesAndMoreLoginData.scope}&redirect_uri=${milesAndMoreLoginData.redirect_uri}&client_id=${milesAndMoreLoginData.client_id}&lang=en`;
  };

  const handleVerify = () => {
    try {
      openMilesAndMoreLogin();
    } catch (e: any) {
      console.error(error);
      setError('Something went wrong');
    }
  };

  const checkNonMember = () => {
    dispatch(setOffer(nonMemberOffer('M&M')));
    navigate(RedirectPaths[RedirectPathsEnum.MILES_MORE_OFFER]);
  };

  return (
    <>
      <>
        <Nav variant="white" />
        <Main>
          {!alreadyClaimed && (
            <>
              <ChakraImage
                src={Image}
                alt="Miles & More Offer redeem image"
                width="250px"
                my="6"
              />
              <Text fontSize="2xl" fontWeight="500" mb="4" textAlign="center">
                Verify your membership
              </Text>
              <Button
                colorScheme="orange"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                mt="4"
                width="240px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={handleVerify}
                fontWeight="500"
              >
                <Text mr="6px" ml="24px">
                  Verify
                </Text>
                <Icon as={IconSVG} width="13px" height="19px" />
              </Button>
              {error && (
                <div>
                  <Text
                    color="error.500"
                    fontSize="m"
                    mt="5"
                    maxWidth={{ base: '100%', sm: '300px' }}
                    mx="auto"
                    textAlign="center"
                  >
                    {error}
                  </Text>
                </div>
              )}
            </>
          )}
          {alreadyClaimed && <AlreadyClaimedOffer />}
        </Main>
      </>
    </>
  );
};
