import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Image as ChakraImage } from '@chakra-ui/react';

import { CreatePlanForm, CreatePlanFormValues } from '@/components/forms';
import { ContentWithSidebarLayout, Main, Nav } from '@/components/layout';
import { useCreatePlan } from '@/hooks/useRuleRedeem';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import './MyCircadianDayCreatePlanPage.css';

import Image from '@/assets/images/circadian-day-small.png';

export const MyCircadianDayCreatePlanPage = () => {
  const navigate = useNavigate();

  const ruleCode = sessionStorage.getItem('ruleCode');

  useEffect(() => {
    if (!ruleCode) {
      navigate(RedirectPaths[RedirectPathsEnum.MY_CIRCADIAN_DAY]);
    }

    sessionStorage.removeItem('planNumber');

    document.title = 'My Circadian Day plan | Timeshifter®';
  }, []);

  const { mutate } = useCreatePlan();

  const getChronotype = (value: string) => {
    switch (value) {
      case 'earlyBird':
        return 'morning';
      case 'nightOwl':
        return 'evening';
      default:
        return value;
    }
  };

  const handleSubmit = (
    values: CreatePlanFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    return new Promise((resolve, reject) => {
      const sleepTime =
        parseFloat(values.sleepTime.value) > 12
          ? parseFloat(values.sleepTime.value) - 24
          : parseFloat(values.sleepTime.value);

      mutate(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          age: values.age,
          gender: values.sex.value,
          normalSleepTime: parseFloat(values.wakeTime.value) - sleepTime + '',
          normalWakeTime: values.wakeTime.value,
          chronotype: getChronotype(values.chronotype.value),
          email: values.email.toLowerCase(),
          ruleCode,
        },
        {
          onSuccess: (data: any) => {
            resolve(data);
            sessionStorage.setItem('planNumber', data.planNumber);
            navigate(RedirectPaths[RedirectPathsEnum.MY_CIRCADIAN_DAY_SUCCESS]);
          },
          onError: (error: any) => {
            if (error?.response?.data?.type === 'EmailAlreadyExistsError') {
              const errorMessage =
                'An account with this email address already exists';
              setError(errorMessage);
              reject(error);
            } else {
              setError(error?.response?.data?.message);
              reject(error?.response?.data?.message);
            }
          },
        }
      );
    });
  };

  const titleContent = (
    <Box textAlign="center">
      <Text fontSize="2xl" fontWeight="500" mb="4">
        Create plan
      </Text>
    </Box>
  );

  const mainContent = <CreatePlanForm onSubmit={handleSubmit} />;

  const asideContent = (
    <Box textAlign="center" className="side-content">
      <ChakraImage
        src={Image}
        alt="Screenshots from the Jet lag app"
        w="100%"
        maxW="300px"
        mx="auto"
        mt="12px"
        mb="20px"
      />
      <Text
        fontSize="18"
        lineHeight="1.36"
        width="100%"
        textAlign="center"
        mt="12px"
        mb="8px"
        mx="auto"
        fontWeight="500"
      >
        My Circadian Day™
        <br /> Daily plan
      </Text>
    </Box>
  );

  return (
    <>
      <Nav variant="default" />
      <Main>
        <ContentWithSidebarLayout
          titleContent={titleContent}
          mainContent={mainContent}
          asideContent={asideContent}
          asideTop="38px"
        />
      </Main>
    </>
  );
};
