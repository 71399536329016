import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  HStack,
} from '@chakra-ui/react';
import { signUpSchema } from './validations';

export type SignUpFormValues = {
  email: string;
  password: string;
  passwordRepeat: string;
};

type SignUpFormProps = {
  onSubmit: (
    data: SignUpFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => Promise<unknown>;
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit }) => {
  const initialValues: SignUpFormValues = {
    email: '',
    password: '',
    passwordRepeat: '',
  };

  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signUpSchema}
      onSubmit={(values, { setSubmitting }) => {
        setError(undefined);
        onSubmit(values, setError).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {({
        isSubmitting,
        isValid,
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
      }) => (
        <Box as={Form} mt="3" width="100%" maxWidth="440px">
          <FormControl id="email" isInvalid={!!touched.email && !!errors.email}>
            <FormLabel>Email</FormLabel>
            <Box>
              <Input
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormErrorMessage fontSize="md">{errors.email}</FormErrorMessage>
            </Box>
          </FormControl>
          <HStack
            spacing="3"
            mt="3"
            alignItems="flex-start"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <FormControl
              id="password"
              isInvalid={!!touched.password && !!errors.password}
            >
              <FormLabel>Password</FormLabel>
              <Box>
                <Input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-password"
                />
                <FormErrorMessage fontSize="md">
                  {errors.password}
                </FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl
              id="passwordRepeat"
              isInvalid={!!touched.passwordRepeat && !!errors.passwordRepeat}
            >
              <FormLabel>Repeat password</FormLabel>
              <Box>
                <Input
                  type="password"
                  name="passwordRepeat"
                  value={values.passwordRepeat}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-password"
                />
                <FormErrorMessage fontSize="md">
                  {errors.passwordRepeat}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </HStack>

          {error && (
            <Text
              color="error.500"
              fontSize="md"
              mt="5"
              maxWidth={{ base: '100%', sm: '300px' }}
              mx="auto"
              textAlign="center"
            >
              {error}
            </Text>
          )}
          <Button
            colorScheme="orange"
            width="100%"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !isValid}
            mt="5"
            fontWeight="500"
          >
            Next
          </Button>
        </Box>
      )}
    </Formik>
  );
};
