import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = process.env.REACT_APP_BACKEND;

// eslint-disable-next-line no-undef
const apiOldBaseURL = process.env.REACT_APP_AUTH_BACKEND;

export const voucherTest = async ({ code }) => {
  try {
    const response = await axios.get(
      `${apiOldBaseURL}/voucher-test?voucherId=${code}`
    );

    return response;
  } catch (error) {
    console.error('Error during voucher test:', error);
    throw error;
  }
};

export const voucherRedeem = async ({ code, token }) => {
  try {
    const response = await axios.get(
      `${apiOldBaseURL}/voucher-redeem?voucherId=${code}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error('Error during voucher redeem:', error);
    throw error;
  }
};

export const generalRedeem = async ({ code }) => {
  try {
    const response = await axios.get(
      `${apiBaseURL}/general-redeem/vouchercodes/${code}`
    );

    return response;
  } catch (error) {
    console.error('Error during general redeem:', error);
    throw error;
  }
};

export const checkUsage = async ({ voucherRuleId, token }) => {
  try {
    const response = await axios.get(
      `${apiBaseURL}/general-redeem/voucherrules/checkusage/${voucherRuleId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error('Error during check usage:', error);
    throw error;
  }
};
