import { useMutation } from 'react-query';
import {
  signIn,
  signUp,
  appleSignIn,
  googleSignIn,
  verifyEmail,
} from '@/api/auth';

export const useSignIn = () => {
  return useMutation(signIn);
};

export const useSignUp = () => {
  return useMutation(signUp);
};

export const useAppleSignIn = () => {
  return useMutation(appleSignIn);
};

export const useGoogleSignIn = () => {
  return useMutation(googleSignIn);
};

export const useVerifyEmail = () => {
  return useMutation(verifyEmail);
};
