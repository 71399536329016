import React, { useEffect } from 'react';

import { MyCircadianDayRedeemForm } from '@/components/forms';
import { Main, Nav } from '@/components/layout';

export const MyCircadianDayPage = () => {
  useEffect(() => {
    document.title = 'My Circadian Day | Timeshifter®';

    sessionStorage.removeItem('ruleCode');
  }, []);

  return (
    <>
      <Nav variant="default" />
      <Main>
        <MyCircadianDayRedeemForm />
      </Main>
    </>
  );
};
