import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Image as ChakraImage, Spinner, Text } from '@chakra-ui/react';

import { Main, Nav } from '@/components/layout';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { useVerifyEmail } from '@/hooks/useAuth';
import Success from '@/assets/images/email-success.png';
import Error from '@/assets/images/email-error.png';

export const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { mutate } = useVerifyEmail();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = 'Verify Email | Timeshifter®';
  }, []);

  useEffect(() => {
    if (!code) {
      navigate(RedirectPaths[RedirectPathsEnum.REDEEM]);
      return;
    }

    try {
      setLoading(true);
      mutate(code, {
        onSuccess: () => {
          setLoading(false);
          setSuccess(true);
        },
        onError: (error) => {
          setLoading(false);
          console.error(error);
        },
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [code]);

  return (
    <>
      <Nav variant="default" />
      <Main>
        {!loading ? (
          success ? (
            <>
              <ChakraImage
                src={Success}
                alt="Your email address has been verified"
                width="135px"
                margin="auto"
                mt="80px"
                mb="0"
              />
              <Text
                color="#2D2C2A"
                fontSize="22"
                minWidth="600"
                lineHeight="30px"
                mt="8"
                mx="auto"
                textAlign="center"
              >
                Your email address has been verified!
              </Text>
            </>
          ) : (
            <>
              <ChakraImage
                src={Error}
                alt="Link is invalid"
                width="135px"
                margin="auto"
                mt="80px"
                mb="0"
              />
              <Text
                color="#2D2C2A"
                fontSize="22"
                minWidth="600"
                lineHeight="30px"
                mt="8"
                mx="auto"
                textAlign="center"
              >
                Link is invalid.
              </Text>
            </>
          )
        ) : (
          <Spinner />
        )}
      </Main>
    </>
  );
};
