import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image as ChakraImage,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Modal from 'react-modal';

import { createPlanSchema } from './validations';
import { ReactComponent as IconSVG } from '@/assets/icons/dropdown.svg';
import Image from '@/assets/images/sleep.svg';
import './CreatePlanForm.css';
import { themeColors, themeFonts } from '@/styles/theme';

Modal.setAppElement('#root');

export type CreatePlanFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  sex: { value: string; label: string };
  age: string | number | undefined;
  sleepTime: { value: string; label: string };
  wakeTime: { value: string; label: string };
  chronotype: { value: string; label: string };
};

type CreatePlanFormProps = {
  onSubmit: (
    data: CreatePlanFormValues,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => Promise<unknown>;
};

export const CreatePlanForm: React.FC<CreatePlanFormProps> = ({ onSubmit }) => {
  const initialValues: CreatePlanFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    sex: { value: '', label: '' },
    age: '',
    sleepTime: { value: '', label: '' },
    wakeTime: { value: '', label: '' },
    chronotype: { value: '', label: '' },
  };

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [error, setError] = useState<string | undefined>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const [wakeOptions, setWakeOptions] = useState<
    { value: string; label: string; outsideRange?: boolean }[]
  >([]);

  const calculateWakeOptions = (sleepTimeOption: { value: string }) => {
    const sleepTimeValue = parseFloat(sleepTimeOption.value);
    const adjustedSleepTime =
      sleepTimeValue > 12 ? sleepTimeValue - 24 : sleepTimeValue;
    const minWakeTime = adjustedSleepTime + 7;
    const maxWakeTime = adjustedSleepTime + 9.5;

    const newOptions = wakeTimes
      .filter(({ value }) => {
        const timeValue = parseFloat(value);
        return timeValue >= minWakeTime && timeValue <= maxWakeTime;
      })
      .map((option) => ({ ...option, outsideRange: false }));

    const initialOption =
      newOptions.length > 0
        ? {
            value: 'before',
            label: 'Before ' + newOptions[0].label,
            outsideRange: true,
          }
        : undefined;

    const finalOption =
      newOptions.length > 0
        ? {
            value: 'after',
            label: 'After ' + newOptions[newOptions.length - 1].label,
            outsideRange: true,
          }
        : null;

    setWakeOptions([
      ...(initialOption ? [initialOption] : []),
      ...newOptions,
      ...(finalOption ? [finalOption] : []),
    ]);
  };

  const sexOptions = [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  const sleepTimeOptions = [
    {
      value: 'before8',
      label: 'Before 8pm',
      outsideRange: true,
    },
    { value: '20', label: '8pm' },
    { value: '20.5', label: '8:30pm' },
    { value: '21', label: '9pm' },
    { value: '21.5', label: '9:30pm' },
    { value: '22', label: '10pm' },
    { value: '22.5', label: '10:30pm' },
    { value: '23', label: '11pm' },
    { value: '23.5', label: '11:30pm' },
    { value: '0', label: '12am' },
    { value: '0.5', label: '12:30am' },
    { value: '1', label: '1am' },
    { value: '1.5', label: '1:30am' },
    { value: '2', label: '2am' },
    {
      value: 'after2',
      label: 'After 2am',
      outsideRange: true,
    },
  ];

  const wakeTimes = [
    { value: '3', label: '3am' },
    { value: '3.5', label: '3:30am' },
    { value: '4', label: '4am' },
    { value: '4.5', label: '4:30am' },
    { value: '5', label: '5am' },
    { value: '5.5', label: '5:30am' },
    { value: '6', label: '6am' },
    { value: '6.5', label: '6:30am' },
    { value: '7', label: '7am' },
    { value: '7.5', label: '7:30am' },
    { value: '8', label: '8am' },
    { value: '8.5', label: '8:30am' },
    { value: '9', label: '9am' },
    { value: '9.5', label: '9:30am' },
    { value: '10', label: '10am' },
    { value: '10.5', label: '10:30am' },
    { value: '11', label: '11am' },
    { value: '11.5', label: '11:30am' },
  ];

  const chronotypeOptions = [
    {
      value: 'earlyBird',
      label: 'Early bird',
    },
    {
      value: 'nightOwl',
      label: 'Night owl',
    },
    {
      value: 'neither',
      label: 'Neither',
    },
  ];

  const dropdownIcon = (isOpen: boolean) => (
    <div
      style={{
        transition: '"transform 0.2s"',
        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    >
      <IconSVG />
    </div>
  );

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="My Dialog"
        className="modalContent"
        style={{
          overlay: {
            backgroundColor: 'rgba(241,236,234, 0.9)',
          },
        }}
      >
        <div
          style={{
            backgroundColor: '#293047',
            borderRadius: '50%',
            width: '68px',
            height: '68px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ChakraImage src={Image} alt="Offer Redeem image" width="38px" />
        </div>
        <Text
          fontSize="18"
          lineHeight="1.36"
          color="#5B5B5B"
          width="360px"
          textAlign="center"
        >
          At this time we only support sleep times starting between 8pm to 2am
          and for durations of 7 to 9,5 hours. Choose the times that are closest
          to your typical sleep start and awake up time.
        </Text>
        <Button
          colorScheme="orange"
          width="110px"
          type="submit"
          onClick={() => closeModal()}
          fontWeight="500"
        >
          Got it
        </Button>
      </Modal>
      <Formik
        initialValues={initialValues}
        validationSchema={createPlanSchema}
        onSubmit={(values, { setSubmitting }) => {
          setError(undefined);
          onSubmit(values, setError).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {({
          isSubmitting,
          isValid,
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Box as={Form} mt="3" width="100%" maxWidth="440px">
            <HStack
              spacing="3"
              mb="3"
              alignItems="flex-start"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <FormControl
                id="firstName"
                isInvalid={!!touched.firstName && !!errors.firstName}
              >
                <FormLabel mb="3px">First name</FormLabel>
                <Box>
                  <Input
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    color="#333232"
                    fontSize="16px"
                    lineHeight="1.36"
                  />
                  <FormErrorMessage fontSize="md">
                    {errors.firstName}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                id="lastName"
                isInvalid={!!touched.lastName && !!errors.lastName}
              >
                <FormLabel mb="3px">Last name</FormLabel>
                <Box>
                  <Input
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    color="#333232"
                    fontSize="16px"
                    lineHeight="1.36"
                  />
                  <FormErrorMessage fontSize="md">
                    {errors.lastName}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </HStack>

            <HStack
              spacing="3"
              mb="3"
              alignItems="flex-start"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <FormControl
                id="email"
                isInvalid={!!touched.email && !!errors.email}
              >
                <FormLabel mb="3px">Email</FormLabel>
                <Box>
                  <Input
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    color="#333232"
                    fontSize="16px"
                    lineHeight="1.36"
                  />
                  <FormErrorMessage fontSize="md">
                    {errors.email}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </HStack>

            <HStack
              spacing="3"
              mb="3"
              alignItems="flex-start"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <FormControl id="age" isInvalid={!!touched.age && !!errors.age}>
                <FormLabel mb="3px">Age</FormLabel>
                <Box>
                  <NumberInput
                    value={values?.age}
                    onChange={(valueAsString, valueAsNumber) =>
                      setFieldValue('age', valueAsNumber || '')
                    }
                    onBlur={() => setFieldTouched('age', true)}
                    min={1}
                    max={99}
                    step={1}
                    className="age-input"
                  >
                    <NumberInputField />
                  </NumberInput>
                  <FormErrorMessage fontSize="md">
                    {errors.age}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              <FormControl id="sex" isInvalid={!!touched.sex && !!errors.sex}>
                <FormLabel mb="3px">Sex</FormLabel>
                <Box>
                  <Menu matchWidth>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          rightIcon={dropdownIcon(isOpen)}
                          as={Button}
                          sx={{
                            width: '100%',
                            height: '40px',
                            borderRadius: '7px',
                            backgroundColor: isOpen
                              ? '#D6D2D3 !important'
                              : '#EAE4E2',
                            fontWeight: '400',
                            textAlign: 'left',
                            border:
                              !!touched.sex?.value && !!errors.sex?.value
                                ? '1px solid #E53E3E'
                                : 'initial',
                            color: '#716E6F',
                            _hover: {
                              backgroundColor: '#D6D2D3',
                            },
                          }}
                        >
                          {values?.sex?.label || 'Select'}
                        </MenuButton>
                        <MenuList>
                          {sexOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              onClick={() => setFieldValue('sex', option)}
                              sx={{
                                _hover: {
                                  backgroundColor: '#EAE4E2',
                                },
                                _focus: {
                                  backgroundColor: '#EAE4E2',
                                },
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                  <FormErrorMessage fontSize="md">
                    {errors.sex?.value}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </HStack>

            <HStack
              spacing="3"
              mb="3"
              alignItems="flex-start"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <FormControl
                id="sleepTime"
                isInvalid={
                  !!touched.sleepTime?.value && !!errors.sleepTime?.value
                }
              >
                <FormLabel mb="3px">Normal sleep time</FormLabel>
                <Box>
                  <Menu matchWidth>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          rightIcon={dropdownIcon(isOpen)}
                          as={Button}
                          sx={{
                            width: '100%',
                            height: '40px',
                            borderRadius: '7px',
                            backgroundColor: isOpen
                              ? '#D6D2D3 !important'
                              : '#EAE4E2',
                            fontWeight: '400',
                            textAlign: 'left',
                            border:
                              !!touched.sleepTime?.value &&
                              !!errors.sleepTime?.value
                                ? '1px solid #E53E3E'
                                : 'initial',
                            color: '#716E6F',
                            _hover: {
                              backgroundColor: '#D6D2D3',
                            },
                          }}
                        >
                          {values?.sleepTime?.label || 'Select'}
                        </MenuButton>
                        <MenuList maxHeight="300px" overflowY="auto">
                          {sleepTimeOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              onClick={() => {
                                if (option.outsideRange) {
                                  openModal();
                                } else {
                                  setFieldValue('wakeTime', {
                                    value: '',
                                    label: '',
                                  });
                                  setFieldValue('sleepTime', option);
                                  calculateWakeOptions(option);
                                }
                              }}
                              sx={{
                                _hover: {
                                  backgroundColor: '#EAE4E2',
                                },
                                _focus: {
                                  backgroundColor: '#EAE4E2',
                                },
                              }}
                              color={option.outsideRange ? '#ff6c66' : 'unset'}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                  <FormErrorMessage fontSize="md">
                    {errors.sleepTime?.value}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              <FormControl
                id="wakeTime"
                isInvalid={
                  !!touched.wakeTime?.value && !!errors.wakeTime?.value
                }
              >
                <FormLabel
                  mb="3px"
                  opacity={
                    values?.sleepTime?.value ||
                    (touched.wakeTime?.value && errors.wakeTime?.value)
                      ? 1
                      : 0.3
                  }
                >
                  Normal wake up time
                </FormLabel>
                <Box>
                  <Menu matchWidth>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          as={Button}
                          rightIcon={dropdownIcon(isOpen)}
                          sx={{
                            width: '100%',
                            height: '40px',
                            borderRadius: '7px',
                            backgroundColor: isOpen
                              ? '#D6D2D3 !important'
                              : '#EAE4E2',
                            fontWeight: '400',
                            textAlign: 'left',
                            border:
                              !!touched.wakeTime?.value &&
                              !!errors.wakeTime?.value
                                ? '1px solid #E53E3E'
                                : 'initial',
                            color: '#716E6F',
                            _hover: {
                              backgroundColor: '#D6D2D3',
                            },
                          }}
                          opacity={
                            values?.sleepTime?.value ||
                            (touched.wakeTime?.value && errors.wakeTime?.value)
                              ? 1
                              : 0.3
                          }
                          cursor={
                            values?.sleepTime?.value ? 'pointer' : 'not-allowed'
                          }
                        >
                          {values?.wakeTime?.label || 'Select'}
                        </MenuButton>
                        {values?.sleepTime?.value && (
                          <MenuList>
                            {wakeOptions.map((option) => (
                              <MenuItem
                                key={option.value}
                                onClick={() => {
                                  if (option.outsideRange) {
                                    openModal();
                                  } else {
                                    setFieldValue('wakeTime', option);
                                  }
                                }}
                                sx={{
                                  _hover: {
                                    backgroundColor: '#EAE4E2',
                                  },
                                  _focus: {
                                    backgroundColor: '#EAE4E2',
                                  },
                                }}
                                color={
                                  option.outsideRange ? '#ff6c66' : 'unset'
                                }
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        )}
                      </>
                    )}
                  </Menu>
                  <FormErrorMessage fontSize="md">
                    {errors.wakeTime?.value}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </HStack>

            <HStack
              spacing="3"
              mb="3"
              alignItems="flex-start"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <FormControl
                id="chronotype"
                isInvalid={!!touched.chronotype && !!errors.chronotype}
              >
                <FormLabel mb="3px">Chronotype</FormLabel>
                <Box>
                  <Menu matchWidth>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          rightIcon={dropdownIcon(isOpen)}
                          as={Button}
                          sx={{
                            width: '100%',
                            height: '40px',
                            borderRadius: '7px',
                            backgroundColor: isOpen
                              ? '#D6D2D3 !important'
                              : '#EAE4E2',
                            fontWeight: '400',
                            textAlign: 'left',
                            border:
                              !!touched.chronotype?.value &&
                              !!errors.chronotype?.value
                                ? '1px solid #E53E3E'
                                : 'initial',
                            color: '#716E6F',
                            _hover: {
                              backgroundColor: '#D6D2D3',
                            },
                          }}
                        >
                          {values?.chronotype?.label || 'Select'}
                        </MenuButton>
                        <MenuList>
                          {chronotypeOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              onClick={() =>
                                setFieldValue('chronotype', option)
                              }
                              sx={{
                                _hover: {
                                  backgroundColor: '#EAE4E2',
                                },
                                _focus: {
                                  backgroundColor: '#EAE4E2',
                                },
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                  <FormErrorMessage fontSize="md">
                    {errors.chronotype?.value}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </HStack>

            {error && (
              <Text
                color="error.500"
                fontSize="md"
                mt="5"
                maxWidth={{ base: '100%', sm: '300px' }}
                mx="auto"
                textAlign="center"
              >
                {error}
              </Text>
            )}
            <Button
              colorScheme="orange"
              width="100%"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !isValid}
              mt="5"
              fontWeight="500"
            >
              Submit
            </Button>

            <Text
              fontSize="12px"
              fontWeight="300"
              mt="5"
              fontFamily={themeFonts.avenir}
              color={themeColors.grayDark}
              lineHeight="1.36"
              width="fit-content"
              marginX="auto"
            >
              <span style={{ display: isMobile ? 'block' : 'inline' }}>
                By continuing, you agree to our{' '}
              </span>
              <Link
                href="https://www.timeshifter.com/terms/terms-of-use"
                isExternal
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                href="https://www.timeshifter.com/terms/privacy-policy"
                isExternal
              >
                Privacy Policy
              </Link>
              .
            </Text>
          </Box>
        )}
      </Formik>
    </>
  );
};
