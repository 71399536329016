import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutesPaths } from '@/utils/constants/routes';
import {
  RedeemPage,
  OfferPage,
  MilesMorePage,
  MileagePlusPage,
  MilesMoreOfferPage,
  MileagePlusOfferPage,
  NotFoundPage,
  SignInPage,
  SignUpPage,
  PaymentPage,
  DownloadAppPage,
  MyCircadianDayPage,
  MyCircadianDayCreatePlanPage,
  MyCircadianDaySuccessPage,
} from '@/pages';
import { MainLayout } from '@/components/layout';
import { VerifyEmailPage } from '@/pages/VerifyEmailPage';

const AppRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={AppRoutesPaths.REDEEM_ROUTE} element={<RedeemPage />} />
        <Route
          path={AppRoutesPaths.REDEEM_ROUTE_CODE}
          element={<RedeemPage />}
        />

        <Route path={AppRoutesPaths.OFFER_ROUTE} element={<OfferPage />} />

        <Route
          path={AppRoutesPaths.MILES_MORE_ROUTE}
          element={<MilesMorePage />}
        />
        <Route
          path={AppRoutesPaths.MILEAGE_PLUS_ROUTE}
          element={<MileagePlusPage />}
        />
        <Route
          path={AppRoutesPaths.MILES_MORE_OFFER_ROUTE}
          element={<MilesMoreOfferPage />}
        />
        <Route
          path={AppRoutesPaths.MILEAGE_PLUS_OFFER_ROUTE}
          element={<MileagePlusOfferPage />}
        />
        <Route path={AppRoutesPaths.SIGNIN_ROUTE} element={<SignInPage />} />
        <Route path={AppRoutesPaths.SIGNUP_ROUTE} element={<SignUpPage />} />
        <Route path={AppRoutesPaths.PAYMENT_ROUTE} element={<PaymentPage />} />
        <Route
          path={AppRoutesPaths.DOWNLOAD_APP_ROUTE}
          element={<DownloadAppPage />}
        />
        <Route
          path={AppRoutesPaths.VERIFY_EMAIL_ROUTE}
          element={<VerifyEmailPage />}
        />
        <Route
          path={AppRoutesPaths.MY_CIRCADIAN_DAY_ROUTE}
          element={<MyCircadianDayPage />}
        />
        <Route
          path={AppRoutesPaths.MY_CIRCADIAN_DAY_ROUTE_CODE}
          element={<MyCircadianDayPage />}
        />
        <Route
          path={AppRoutesPaths.MY_CIRCADIAN_DAY_CREATE_PLAN_ROUTE}
          element={<MyCircadianDayCreatePlanPage />}
        />
        <Route
          path={AppRoutesPaths.MY_CIRCADIAN_DAY_SUCCESS_ROUTE}
          element={<MyCircadianDaySuccessPage />}
        />
        {/* This will catch unmatched routes */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
