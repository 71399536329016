import React, { ReactNode } from 'react';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { themeColors } from '@/styles/theme';

type ContentWithSidebarLayoutProps = {
  titleContent: ReactNode;
  mainContent: ReactNode;
  asideContent?: ReactNode;
  hideAsideOnMobile?: boolean;
  asideTop?: string;
};

export const ContentWithSidebarLayout: React.FC<
  ContentWithSidebarLayoutProps
> = ({
  titleContent,
  mainContent,
  asideContent,
  hideAsideOnMobile,
  asideTop,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 991px)');

  return (
    <Flex as="section" direction="column" width="100%" maxWidth="440px">
      <Box width="100%" maxWidth="440px" mb="5">
        {titleContent}
      </Box>
      {isMobile ? (
        <>
          {!hideAsideOnMobile && asideContent && (
            <Box
              as="aside"
              width="100%"
              bg={themeColors.beigeDark}
              p="8"
              mb="5"
              borderRadius="lg"
            >
              {asideContent}
            </Box>
          )}
          <Box width="100%" maxWidth="440px">
            {mainContent}
          </Box>
        </>
      ) : (
        <Box width="100%" maxWidth="440px" position="relative">
          {mainContent}

          {asideContent && (
            <Box
              as="aside"
              width="240px"
              bg={themeColors.beigeDark}
              px="8"
              py="4"
              borderRadius="lg"
              position="absolute"
              top={asideTop || 0}
              right="-272px"
            >
              {asideContent}
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ContentWithSidebarLayout;
