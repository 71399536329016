import { Account, CurrentAccountState } from '@/utils/types/account';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';

const initialState: CurrentAccountState = {
  currentAccount: null,
};

const currentAccountSlice = createSlice({
  name: 'currentAccount',
  initialState,
  reducers: {
    setCurrentAccount(state, action: PayloadAction<Account>) {
      state.currentAccount = action.payload;
    },
    clearCurrentAccount(state) {
      state.currentAccount = null;
    },
  },
});

// Export selector
export const selectCurrentAccount = createSelector(
  (state: AppState) => state.currentAccount,
  (currentAccountState) => currentAccountState.currentAccount
);

// Export actions
export const { setCurrentAccount, clearCurrentAccount } =
  currentAccountSlice.actions;

// Export reducer
export default currentAccountSlice.reducer;
