import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';

import { Main, Nav } from '@/components/layout';
import { AlreadyClaimedOffer, ValidOffer } from '@/components/offers';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { AppState } from '@/store';
import { selectOffer } from '@/store/offer/offer.slice';

export const MileagePlusOfferPage = () => {
  const navigate = useNavigate();
  const offer = useSelector((state: AppState) => selectOffer(state));

  useEffect(() => {
    if (!offer) {
      navigate(RedirectPaths[RedirectPathsEnum.MILEAGE_PLUS]);
    }
  }, [offer, navigate]);

  useEffect(() => {
    document.title = 'MileagePlus offer | Timeshifter®';
  }, []);

  // TODO: Check types.
  let subtitle = '';
  let buttonText = '';
  const alreadyClaimed = offer?.type === 'claimed';

  switch (offer?.type) {
    case 'subscription':
      subtitle = '1-year subscription';
      buttonText = offer.description || 'Redeem';
      break;
    case 'free-trial':
      subtitle = 'Free trial plan';
      buttonText = 'Redeem';
      break;
    case 'annual-subscription':
      subtitle = 'Annual subscription';
      buttonText = 'Redeem';
      break;
    case 'annual-subscription-discount':
      subtitle = 'Annual subscription';
      buttonText = offer.description || 'Redeem';
      break;
  }

  return (
    <>
      <Nav variant="blue" />
      <Main>
        {alreadyClaimed && <AlreadyClaimedOffer />}
        {/* <NotEligibleOffer offersURL={AppRoutesPaths.REDEEM_ROUTE} /> */}

        {!alreadyClaimed && subtitle && (
          <ValidOffer subTitle={subtitle}>
            {/* <Text fontSize="lg" fontWeight="500">
            (<Text as="s">$24.99</Text> $19.99)
          </Text> */}
            {/* <Button
              onClick={() => console.log('Tada')}
              colorScheme="orange"
              width="100%"
              mb="2"
            >
              Use 1,000 miles to redeem (you have 70,613 miles)
            </Button> */}

            <Button
              onClick={() => navigate(RedirectPaths[RedirectPathsEnum.SIGNUP])}
              colorScheme="orange"
              fontWeight="500"
            >
              {buttonText}
            </Button>
          </ValidOffer>
        )}
      </Main>
    </>
  );
};
