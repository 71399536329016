import axios from 'axios';

// eslint-disable-next-line no-undef
const apiBaseURL = process.env.REACT_APP_BACKEND;

export const tokenExchange = async ({ code, currency }) => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/miles-and-more/tokenexchange`,
      {
        code,
        currency,
      }
    );

    return response;
  } catch (error) {
    console.error('Error during token exchange:', error);
    throw error;
  }
};
