import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Image as ChakraImage, Box, Text, Link } from '@chakra-ui/react';

import Image from '@/assets/images/claim-coupon.svg';
import { Main, Nav } from '@/components/layout';
// import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';

export const NotFoundPage = () => {
  useEffect(() => {
    document.title = 'Not found | Timeshifter®';
  }, []);

  return (
    <>
      <Nav variant="default" />
      <Main>
        <ChakraImage
          src={Image}
          alt="Page not found image"
          boxSize="300px"
          mt="4"
        />
        <Box textAlign="center">
          <Text as="h1" fontSize="2xl" mt="4">
            I couldn&apos;t find the page you were looking for.
          </Text>
          <Text mt="2">
            Maybe the URL you entered was wrong, or the page you are looking for
            has been moved or deleted.
          </Text>
          <Text mt="2">
            Click{' '}
            <Link as={RouterLink} to={'https://www.timeshifter.com/'}>
              here
            </Link>{' '}
            to return to our homepage.
          </Text>
        </Box>
      </Main>
    </>
  );
};
