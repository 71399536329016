import React from 'react';
import AppRouter from '@/app/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store } from '@/store';
import { theme } from '@/styles';

// Create a new instance of the QueryClient
const queryClient = new QueryClient();

const App = () => {
  return (
    <ReduxProvider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
          </QueryClientProvider>
        </BrowserRouter>
      </ChakraProvider>
    </ReduxProvider>
  );
};

export default App;
