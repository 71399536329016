import { extendTheme, ThemeConfig } from '@chakra-ui/react';

// Colors
export const themeColors = {
  white: '#FFFFFF',
  black: '#000000',
  beige: '#F5F1EE',
  beigeDark: '#EEE8E3',
  gray: '#B9B5B6',
  grayLight: '#E0E0E0',
  grayMedium: '#9C9B9C',
  grayDark: '#333232',
  orange: '#EA7100',
  orangeDark: '#d86e0b',
  red: '#FF6C67',
  blueDark: '#293047',
  fontDark: '#2D2C2A',
  fontLight: '#5B5B5B',
};

// Fonts
export const themeFonts = {
  avenir: '"Avenir","Helvetica Neue",Helvetica,Arial,sans-serif',
  avenirNext: '"Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif',
};

export const theme: ThemeConfig = extendTheme({
  // Pallete
  colors: {
    primary: {
      500: themeColors.orange,
      600: themeColors.orangeDark,
      contrastText: themeColors.beige,
    },
    secondary: {
      500: themeColors.grayMedium,
      600: themeColors.black,
      contrastText: themeColors.beige,
    },
    info: {
      500: themeColors.black,
      600: themeColors.grayMedium,
      contrastText: themeColors.beige,
    },
    error: {
      500: themeColors.red,
    },
    background: {
      default: themeColors.beige,
    },
    orange: {
      500: themeColors.orange,
      600: themeColors.orangeDark,
    },
  },
  // Typography
  fonts: {
    heading: themeFonts.avenirNext,
    body: themeFonts.avenirNext,
  },
  // Shape and sizes
  sizes: {
    // TODO: add here the size of your navigation
    // TODO: add here the size of your images
  },
  radii: {
    md: '7px',
    lg: '10px',
  },
  // Global styles
  styles: {
    global: {
      body: {
        fontFamily: themeFonts.avenirNext,
        backgroundColor: themeColors.beige,
        color: themeColors.fontDark,
      },
    },
  },
  // Components overrides
  components: {
    // Buttons
    Button: {
      baseStyle: {
        whiteSpace: 'normal',
        borderRadius: '100px',
        _hover: {
          _disabled: { background: 'transparent' },
        },
        _disabled: {
          backgroundColor: 'transparent',
          color: themeColors.grayMedium,
          border: `1px solid ${themeColors.grayMedium}`,
        },
      },
      sizes: {
        sm: {
          fontSize: '16px',
          lineHeight: '22px',
          height: 'auto',
          minHeight: '38px',
          px: '12px',
          py: '8px',
        },
        md: {
          fontSize: '18px',
          lineHeight: '22px',
          height: 'auto',
          minHeight: '40px',
          px: '16px',
          py: '8px',
        },
      },
      variants: {
        grayButton: {
          bg: themeColors.grayMedium,
          color: themeColors.beige,
          border: 0,
          _hover: {
            bg: themeColors.gray,
          },
        },
        whiteButton: {
          bg: themeColors.white,
          color: themeColors.fontLight,
          fontFamily: themeFonts.avenir,
          fontWeight: 500,
          fontSize: '16px',
          border: '1px solid transparent',
          _hover: {
            borderColor: themeColors.grayMedium,
          },
        },
        blackButton: {
          bg: themeColors.black,
          color: themeColors.white,
          fontFamily: themeFonts.avenir,
          fontWeight: 500,
          fontSize: '16px',
          border: '1px solid transparent',
          _hover: {
            bg: themeColors.grayDark,
          },
        },
      },
    },
    // Inputs
    Input: {
      variants: {
        outline: {
          field: {
            color: themeColors.grayDark,
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
            background: themeColors.white,
            borderColor: themeColors.grayLight,
            borderRadius: 'md',
            outline: 'none',
            _hover: {
              borderColor: themeColors.grayMedium,
            },
            _focus: {
              borderColor: themeColors.grayMedium,
              boxShadow: 'none',
            },
            _focusWithin: {
              borderColor: themeColors.grayMedium,
              boxShadow: 'none',
            },
            _invalid: {
              color: themeColors.red,
              borderColor: themeColors.red,
              boxShadow: 'none',
            },
            _placeholder: {
              color: themeColors.gray,
            },
            _autofill: {
              borderColor: themeColors.grayLight,
              textFillColor: themeColors.grayDark,
              boxShadow: '0 0 0px 1000px themeColors.white inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: themeFonts.avenir,
        fontWeight: 400,
        color: themeColors.fontLight,
        fontSize: '16px',
        lineHeight: '22px',
        mx: 0,
      },
    },
    // Links
    Link: {
      baseStyle: {
        textDecoration: 'underline',
        _hover: {
          textDecoration: 'none',
        },
        _focus: {
          textDecoration: 'none',
        },
        _focusWithin: {
          textDecoration: 'none',
        },
      },
    },
  },
});

/*
Chakra UI default breakpoints:
xs: 0px
sm: 30em (480px)
md: 48em (768px)
lg: 62em (992px)
xl: 80em (1280px)
2xl: 96em (1536px)
*/
