import React from 'react';
import { Box, Button, Text, Image as ChakraImage } from '@chakra-ui/react';
import { themeFonts } from '@/styles/theme';

import Image from '@/assets/images/already-claimed.svg';

export const AlreadyClaimedOffer: React.FC = () => {
  return (
    <Box>
      <ChakraImage src={Image} alt="Offer Redeem image" width="250px" my="6" />
      <Text
        fontFamily={themeFonts.avenir}
        fontSize="xl"
        mb="4"
        mx="auto"
        textAlign="center"
      >
        Already claimed!
      </Text>
      <Button
        as="a"
        href="https://www.timeshifter.com/support"
        width="100%"
        mt="4"
        variant="grayButton"
        target="_blank"
        rel="noopener noreferrer"
      >
        Chat with support
      </Button>
    </Box>
  );
};
