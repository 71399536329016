import React, { ReactNode } from 'react';
import { Button, Flex, Link, Text, useMediaQuery } from '@chakra-ui/react';
import Image from '@/assets/images/jetlag-half.png';
import { Image as ChakraImage } from '@chakra-ui/react';
import { themeColors, themeFonts } from '@/styles/theme';

type ValidOfferProps = {
  subTitle?: string;
  onButtonClick?: () => void;
  children?: ReactNode;
};

export const ValidOffer: React.FC<ValidOfferProps> = ({
  subTitle,
  onButtonClick,
  children,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex direction="column" alignItems="center" textAlign="center">
      <ChakraImage
        src={Image}
        alt="Screenshots from the Jet lag app"
        maxWidth="500px"
        w="100%"
        my="5"
      />
      <Text fontSize="2xl" fontWeight="500">
        Make jet lag history
      </Text>
      {subTitle && (
        <Text fontSize="xl" mb="4" fontWeight="500">
          {subTitle}
        </Text>
      )}
      {children}
      {onButtonClick && (
        <Button
          onClick={onButtonClick}
          colorScheme="orange"
          width="100%"
          mt="4"
          fontWeight="500"
        >
          Next
        </Button>
      )}
      <Text
        fontSize="sm"
        fontWeight="300"
        mt="3"
        fontFamily={themeFonts.avenir}
        color={themeColors.grayDark}
        lineHeight="19px"
      >
        <span style={{ display: isMobile ? 'block' : 'inline' }}>
          By continuing, you agree to our{' '}
        </span>
        <Link href="https://www.timeshifter.com/terms/terms-of-use" isExternal>
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          href="https://www.timeshifter.com/terms/privacy-policy"
          isExternal
        >
          Privacy Policy
        </Link>
        .
      </Text>
    </Flex>
  );
};
