import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import { Main } from '@/components/layout';
import { ValidOffer } from '@/components/offers';
import { RedirectPaths, RedirectPathsEnum } from '@/utils/constants/routes';
import { AppState } from '@/store';
import { selectOffer } from '@/store/offer/offer.slice';
import { splitDescription } from '@/utils/helpers/string.helper';

export const OfferPage = () => {
  const navigate = useNavigate();
  const offer = useSelector((state: AppState) => selectOffer(state));

  useEffect(() => {
    document.title = 'Offer | Timeshifter®';
  }, []);

  useEffect(() => {
    if (!offer) {
      navigate(RedirectPaths[RedirectPathsEnum.REDEEM]);
    }
  }, [offer, navigate]);

  const descriptionSplit = offer?.discount
    ? splitDescription(offer?.description || '')
    : '';

  return (
    <>
      <Main>
        {offer && (
          <ValidOffer
            subTitle={
              offer?.voucherRuleId === 'ecd786a8-83af-499a-82f1-b8c4f40aca5c'
                ? 'Lifetime subscription'
                : offer.type === 'subscription'
                  ? '1-year subscription'
                  : offer.type === 'credit'
                    ? 'Free jet lag plan'
                    : offer.type
            }
          >
            <Button
              onClick={() => navigate(RedirectPaths[RedirectPathsEnum.SIGNUP])}
              colorScheme="orange"
              fontWeight="500"
            >
              {offer.discount && descriptionSplit ? (
                <span>
                  {descriptionSplit[1]}
                  <span style={{ textDecoration: 'line-through' }}>
                    {descriptionSplit[2]}
                  </span>
                  {descriptionSplit[3]}
                </span>
              ) : (
                offer.description
              )}
            </Button>
          </ValidOffer>
        )}
      </Main>
    </>
  );
};
