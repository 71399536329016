import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAW9xpR2EAxfGahsQ81sllcpJCOPK7u1IY',
  authDomain: 'timeshifter-web.firebaseapp.com',
  // Other config properties
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get a reference to the Auth service
const auth = getAuth(app);

export { auth, GoogleAuthProvider };
